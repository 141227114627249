import { Box, Flex, useTheme } from '@chakra-ui/react';

import BulletNumber from '@/components/shared/BulletNumber';
import Heading from '@/components/shared/Heading';
import React from 'react';
import Text from '@/components/shared/Text';

interface Props {
  body: string;
  hideLine?: boolean;
  number: number;
  title: string;
}

const HowItWorksRow: React.FC<Props> = ({ body, hideLine, number, title }: Props) => {
  const theme = useTheme();

  return (
    <Flex pt="2em" alignItems="center">
      <BulletNumber number={number} />

      {hideLine != true && (
        <Box position="relative">
          <Box zIndex="-1" position="absolute" top="0" left="-17px">
            <svg>
              <line x="0" y1="0" y2="100" stroke={theme.colors.gray[100]} strokeWidth="2px" />
            </svg>
          </Box>
        </Box>
      )}

      <Box ml="1.5em" flex={1}>
        <Heading fontSize="2xl" mb="6px">
          {title}
        </Heading>
        <Text fontSize="lg" color="gray.500">
          {body}
        </Text>
      </Box>
    </Flex>
  );
};
export default HowItWorksRow;
