import { Flex, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';

import Button from '@/components/shared/Button';
import { ButtonType } from '@/types';
import CozyLogo from '@/components/layout/CozyLogo';
import HowItWorksRow from './HowItWorksRow';
import React from 'react';
import { useModalRouterParams } from '@/hooks/useModalRouterParams';

interface Props {
  onClose: () => void;
}

export const HOW_IT_WORKS_BULLETS = [
  { number: 1, body: 'Deposit funds to increase your Borrowing Power', title: 'Deposit funds' },
  { number: 2, body: 'Borrow assets protected against a DeFi vulnerability', title: 'Borrow protected assets' },
  { number: 3, body: 'Invest your borrowed assets and earn*', title: 'Earn interest' },
  {
    number: 4,
    hideLine: true,
    body: 'If the vulnerability occurs and you lose your invested assets, you keep your original deposits',
    title: 'Keep Your Principal Protected',
  },
];

const HowItWorksModal: React.FC<Props> = ({ onClose }: Props) => {
  const { howItWorksModalIsOpen: howItWorksModalOpen } = useModalRouterParams();

  return (
    <Modal size="lg" isOpen={howItWorksModalOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="2xl">
        <Flex justifyContent="center" mt="1.5em">
          <CozyLogo height={50} full />
        </Flex>

        <ModalBody px="2em" pb="1.5em" pt="-0.5em">
          {HOW_IT_WORKS_BULLETS.map((bullet) => (
            <HowItWorksRow key={bullet.number} {...bullet} />
          ))}

          <Flex justifyContent="center" mt="3em" mb="1em">
            <Button minW="20em" onClick={onClose} appButtonType={ButtonType.Primary}>
              Got it!
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default HowItWorksModal;
