import { BadgerAccountSubgraphData, BadgerMarketSubgraphData } from '@/investmentPlatforms/badger';
import { CompoundAccountSubgraphData, CompoundMarketSubgraphData } from '@/investmentPlatforms/compound';
import { InvestmentOpportunity, InvestmentPlatform, Market } from '@/types';
import { YearnAccountSubgraphData, YearnApiVault } from '@/investmentPlatforms/yearn';

import investmentPlatforms from '@/investmentPlatforms';

export type InvestMarketSubgraphData = BadgerMarketSubgraphData | CompoundMarketSubgraphData | YearnApiVault[];
export type InvestAccountSubgraphData =
  | BadgerAccountSubgraphData
  | CompoundAccountSubgraphData
  | YearnAccountSubgraphData;

export const determineInvestRateForInvestmentOpportunity = (
  investmentOpportunity: InvestmentOpportunity,
  investmentPlatform: InvestmentPlatform,
  data: InvestMarketSubgraphData,
): number | null => {
  if (investmentOpportunity != null && data != null) {
    const interestRate = investmentPlatform.determineInvestRate(investmentOpportunity, data);

    return interestRate;
  } else {
    return null;
  }
};

export const determineInvestBalanceForInvestmentOpportunity = (
  investmentOpportunity: InvestmentOpportunity,
  investmentPlatform: InvestmentPlatform,
  data: InvestAccountSubgraphData,
  market: Market,
): number | null => {
  if (investmentOpportunity != null && data != null) {
    return investmentPlatform.determineInvestBalanceUnderlying(investmentOpportunity, data, market);
  } else {
    return null;
  }
};

export const filterByInvestMarkets = (markets: Market[]): Market[] => {
  const investmentOpportunities = investmentPlatforms.flatMap((platform) => platform.investmentOpportunities);

  return markets.filter((market) =>
    investmentOpportunities.some(
      (opty: InvestmentOpportunity) => opty.cozyMarketAddress.toLowerCase() === market.id.toLowerCase(),
    ),
  );
};

export const determineInvestLastUpdatedBockNumber = (
  investmentOpportunity: InvestmentOpportunity,
  investmentPlatform: InvestmentPlatform,
  data: InvestAccountSubgraphData,
  market: Market,
): number => {
  if (investmentOpportunity != null && data != null) {
    return investmentPlatform.determineLastUpdatedBlockNumber(investmentOpportunity, data, market);
  } else {
    return null;
  }
};
