import { COMPOUND_SUBGAPH_URLS, DEFAULT_CHAIN_ID, PLATFORM_IDS, SUBGRAPH_URLS, YEARN_API_URLS } from '@/constants';
import { Comptroller, Market, Trigger } from '@/types';
import { fetcher, graphQLFetcher } from '@/utils/fetcher';

import { COMPOUND_MARKETS_QUERY } from './fetchQueries';
import { determineInvestRateForInvestmentOpportunity } from '@/helpers/investHelpers';
import { findInvestInfoForMarket } from '@/helpers/findHelpers';
import useSWR from 'swr';
import { useWeb3React } from '@web3-react/core';

export interface SubgraphData {
  comptroller: Comptroller;
  loading: boolean;
  markets: Market[];
  triggers: Trigger[];
}

const useFetchInvestMarketData = (markets: Market[]): { loadingInvestMarkets: boolean; enhancedMarkets: Market[] } => {
  const { chainId: activeChainId } = useWeb3React();

  const chainId = activeChainId ?? DEFAULT_CHAIN_ID;

  const API = SUBGRAPH_URLS[chainId];

  const shouldQuery = chainId != null && API != null;

  const data = {};

  const { data: compoundData } = useSWR(
    () => (shouldQuery && COMPOUND_SUBGAPH_URLS[chainId] ? `compound-${chainId}` : null),
    graphQLFetcher(COMPOUND_SUBGAPH_URLS[chainId], COMPOUND_MARKETS_QUERY),
  );
  data[PLATFORM_IDS.Compound] = compoundData;

  const { data: yearnData } = useSWR(
    () => (shouldQuery && YEARN_API_URLS[chainId] ? `yearn-${chainId}` : null),
    fetcher(YEARN_API_URLS[chainId]),
  );
  data[PLATFORM_IDS.Yearn] = yearnData;

  const allPlatformsReturned =
    (compoundData != null || COMPOUND_SUBGAPH_URLS[chainId] == null) &&
    (yearnData != null || YEARN_API_URLS[chainId] == null);

  // const { data: badgerData } = useSWR(
  //   () => (shouldQuery && BADGER_API_URL[chainId] ? `badger-${chainId}` : null),
  //   fetcher(BADGER_API_URL[chainId]),
  // );
  // data[PLATFORM_IDS.Badger] = badgerData;

  // If not enough data to query, return empty state to user
  if (!shouldQuery) {
    return {
      loadingInvestMarkets: false,
      enhancedMarkets: markets,
    };
  }

  // If no account data returned, must still be loading & return loading state to user
  if (!allPlatformsReturned) {
    return {
      loadingInvestMarkets: true,
      enhancedMarkets: markets,
    };
  }

  const enhancedMarkets = markets?.map((market) => {
    const { investmentOpportunity, investmentPlatform } = findInvestInfoForMarket(market);

    const relevantData = data[investmentOpportunity?.platformId];

    return {
      ...market,
      investRate: determineInvestRateForInvestmentOpportunity(investmentOpportunity, investmentPlatform, relevantData),
    };
  });

  return { enhancedMarkets, loadingInvestMarkets: false };
};

export default useFetchInvestMarketData;
