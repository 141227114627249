import { Box, Flex } from '@chakra-ui/react';

import BulletNumber from '../shared/BulletNumber';
import Heading from '../shared/Heading';
import { Image } from '@/components/shared/Image';
import React from 'react';
import Text from '@/components/shared/Text';

interface Props {
  body: string;
  number: number;
  title: string;
}

const HowCozyWorksColumn: React.FC<Props> = ({ body, number, title }: Props) => {
  return (
    <Flex
      backgroundColor="white"
      p="1.5em"
      pb="0.5em"
      justifyContent="space-between"
      flexDirection="column"
      width="255px"
      minHeight="315px"
    >
      <Box>
        <BulletNumber size="20px" number={number} fontSize="xs" />
        <Heading fontSize="xl" mb="1rem" mt="1.5em">
          {title}
        </Heading>
        <Text fontSize="md" color="gray.500">
          {body}
        </Text>
      </Box>
      <Image
        htmlWidth="253.5"
        htmlHeight="102"
        src={`/images/graphics/how_it_works/how_it_works_${number}.png`}
        alt={`${title} graphic`}
      />
    </Flex>
  );
};
export default HowCozyWorksColumn;
