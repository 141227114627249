import { Box, Center, LayoutProps, TypographyProps, useTheme } from '@chakra-ui/react';

import React from 'react';
import Text from '@/components/shared/Text';

interface Props {
  fontSize?: TypographyProps['fontSize'];
  number: number;
  size?: LayoutProps['height'];
}

const BulletNumber: React.FC<Props> = ({ fontSize = 'lg', number, size = '33px' }: Props) => {
  const theme = useTheme();
  return (
    <Box>
      <Center height={size} width={size} borderRadius="full" backgroundColor={theme.utilityColors.accentColor}>
        <Text fontWeight="600" fontSize={fontSize} color="white">
          {number}
        </Text>
      </Center>
    </Box>
  );
};
export default BulletNumber;
