import Button from '../shared/Button';
import { ButtonType } from '@/types';
import React from 'react';
import { trackEvent } from '@/utils/analytics';
import { useRouter } from 'next/router';

interface Props {
  appButtonType?: ButtonType;
  location: string;
}

const DevelopersButton: React.FC<Props> = ({ appButtonType = ButtonType.Secondary, location }: Props) => {
  const router = useRouter();

  const onClick = () => {
    trackEvent('Developers Clicked', { location });
    router.push('/developers');
  };

  return (
    <Button onClick={onClick} appButtonType={appButtonType} minW={{ base: '12.5em', lg: '15em' }}>
      Developers
    </Button>
  );
};

export default DevelopersButton;
