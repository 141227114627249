import Button from '../shared/Button';
import { ButtonType } from '@/types';
import React from 'react';
import { trackEvent } from '@/utils/analytics';
import { useRouter } from 'next/router';

interface Props {
  location: string;
}

const GetStartedButton: React.FC<Props> = ({ location }: Props) => {
  const router = useRouter();

  const onClick = () => {
    trackEvent('Get Started Clicked', { location });
    router.push('/protected-invest');
  };

  return (
    <Button onClick={onClick} appButtonType={ButtonType.Primary} minW={{ base: '15em', sm: '12em', md: '15em' }}>
      Get Started
    </Button>
  );
};
export default GetStartedButton;
