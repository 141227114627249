import { Box, Flex, Stack, useTheme } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import HeroHeading from './HeroHeading';
import { Image } from '../shared/Image';

interface Props {
  accentText: string;
  image?: ReactNode;
  imageUrl?: string;
  mainText: string;
  primaryButton: ReactNode;
  secondaryButton?: ReactNode;
}

const HeroSection: React.FC<Props> = ({
  accentText,
  image,
  imageUrl,
  mainText,
  primaryButton,
  secondaryButton,
}: Props) => {
  const theme = useTheme();

  return (
    <Flex flex={1} alignItems="center" justifyContent={{ base: 'center', md: 'space-between' }}>
      <Box flex={{ base: 1 }}>
        <Box maxW={maxTextWidth(imageUrl)}>
          <HeroHeading color={theme.utilityColors.brandColor}>{accentText}</HeroHeading>
          <HeroHeading>{mainText}</HeroHeading>
        </Box>
        <Stack direction={{ base: 'column', sm: 'row' }} spacing={4} mt={{ base: 8, md: '2em' }}>
          {primaryButton}
          {secondaryButton}
        </Stack>
      </Box>
      {(image || imageUrl) && (
        <Box display={{ base: 'none', md: 'block' }} flex={{ base: 0, lg: 0.87 }}>
          {image ? image : imageUrl && <Image src={imageUrl} htmlHeight="420" htmlWidth="420" />}
        </Box>
      )}
    </Flex>
  );
};

const maxTextWidth = (imageUrl: string | null) => {
  if (imageUrl) {
    return { sm: '470px', md: '300px', lg: '400px', xl: '920px' };
  } else {
    return { sm: '400px', md: '580px', lg: '660px', xl: '920px' };
  }
};

export default HeroSection;
