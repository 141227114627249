import { Flex, Link, SimpleGrid, useTheme } from '@chakra-ui/react';

import Container from '../shared/Container';
import Heading from '../shared/Heading';
import { Image } from '../shared/Image';
import React from 'react';
import Text from '@/components/shared/Text';

const investorLogos = ['polychain', 'electric', 'variant', 'dragonfly', 'robot', 'cms', 'coinbase'];

const CONTAINER_WIDTH = '182px';
const CONTAINER_HEIGHT = '171px';

const Investors: React.FC = () => {
  const theme = useTheme();

  return (
    <Flex
      width="100%"
      flexDir="column"
      alignItems="center"
      pb={{ base: '2.5em', md: '5em', lg: '10em' }}
      pt={{ base: '2.5em', md: '7.5em', lg: '5em' }}
    >
      <Flex maxW={theme.maxPageWidth} width="100%" px={{ base: 4, xl: 8 }} flexDir="column">
        <Heading fontSize={{ base: 'xl', md: '3xl' }} fontWeight="800" textAlign="center" mb="1rem">
          Cozy’s backed by the best
        </Heading>
        <Text textAlign="center" fontSize={{ base: 'md', md: 'xl' }} color="gray.500">
          Funding from world class investors in crypto
        </Text>
      </Flex>

      <SimpleGrid
        columns={{ base: 1, sm: 2, md: 3, lg: 4 }}
        spacing={{ base: '1em', md: '3.75em' }}
        mt={{ base: '3em', md: '3.75em' }}
      >
        {investorLogos.map((investor, index) => (
          <Container width={CONTAINER_WIDTH} height={CONTAINER_HEIGHT} key={index} boxShadow="lg" border="0px">
            <Image htmlWidth="152" htmlHeight="138" src={`/images/investors/${investor}.png`} alt={investor} />
          </Container>
        ))}

        <Link isExternal href="https://www.crunchbase.com/organization/cozy-finance">
          <Container
            alignItems="center"
            border="0px"
            boxShadow="lg"
            display="flex"
            height={CONTAINER_HEIGHT}
            justifyContent="center"
            width={CONTAINER_WIDTH}
          >
            <Heading textAlign="center" fontSize="lg">
              See All
            </Heading>
          </Container>
        </Link>
      </SimpleGrid>
    </Flex>
  );
};
export default Investors;
