import { Account, Market, PositionTypes } from '@/types';
import { Box, Flex, useTheme } from '@chakra-ui/react';

import Button from '../shared/Button';
import Divider from '../shared/Divider';
import React from 'react';
import TableRow from '../shared/Table/TableRow';
import Text from '@/components/shared/Text';
import TokenLogo from '../shared/TokenLogo';
import TriggerRowCell from '../provide/TriggerRowCell';
import { calculatePositionStats } from '@/helpers/positionHelpers';

interface Props {
  account?: Account;
  columnAlignments: ('left' | 'right')[];
  columnWidths: number[];
  index: number;
  market: Market;
  onOpen: () => void;
}

const ProtectedBorrowTableRow: React.FC<Props> = ({ columnAlignments, columnWidths, index, market, onOpen }: Props) => {
  const theme = useTheme();

  const { formattedBorrowRate, formattedInvestRate, formattedNetRate } = calculatePositionStats(null, market);

  return (
    <Box>
      {index != 0 && <Divider />}
      <TableRow px={6} py={4} alignItems="center" onClick={onOpen}>
        <Flex flex={columnWidths[0]} alignItems="center">
          <TokenLogo boxSize={8} name={market.underlyingName} symbol={market.underlyingSymbol} />
          <Box ml={4}>
            <Text fontSize="md" fontWeight="600">
              {market.underlyingName}
            </Text>
          </Box>
        </Flex>

        <TriggerRowCell market={market} flex={columnWidths[1]} positionType={PositionTypes.Borrow} />

        <Box flex={columnWidths[2]} textAlign={columnAlignments[2]} alignItems="center">
          <Text fontSize="md" fontWeight="600" color="gray.500">
            {formattedInvestRate}
          </Text>
        </Box>

        <Text position="relative" left="32px" fontSize="md" color="gray.200" textAlign="center">
          {'-'}
        </Text>

        <Box flex={columnWidths[3]} textAlign={columnAlignments[3]} alignItems="center">
          <Text fontSize="md" fontWeight="600">
            {formattedBorrowRate}
          </Text>
        </Box>

        <Text position="relative" left="32px" fontSize="md" color="gray.200" textAlign="center">
          {'='}
        </Text>

        <Box flex={columnWidths[4]} textAlign={columnAlignments[4]} alignItems="center">
          <Text fontSize="md" fontWeight="600" color={theme.utilityColors.successColor}>
            {formattedNetRate}
          </Text>
        </Box>

        <Flex flex={columnWidths[5]} justifyContent="flex-end">
          <Button onClick={onOpen} mr="-px">
            View
          </Button>
        </Flex>
      </TableRow>
    </Box>
  );
};
export default ProtectedBorrowTableRow;
