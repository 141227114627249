import { Box, Flex, useBreakpointValue, useTheme } from '@chakra-ui/react';
import { ButtonType, Market } from '@/types';
import { SortFields, sortMarkets } from '@/helpers/sortHelpers';

import { BorrowModalTabs } from '../modals/BorrowModal';
import Button from '../shared/Button';
import Container from '../shared/Container';
import Heading from '../shared/Heading';
import NoMarketsFound from '../funds/NoMarketsFound';
import ProtectedBorrowTableRow from './ProtectedInvestTableRow';
import React from 'react';
import SectionLink from './SectionLink';
import Table from '../shared/Table/Table';
import { trackEvent } from '@/utils/analytics';
import { useRouter } from 'next/router';
import { useSortRouterParams } from '@/hooks/useSortRouterParams';

interface Props {
  markets: Market[];
}

const NUM_MARKETS_TO_SHOW = 5;

const ProtectedBorrowTable: React.FC<Props> = ({ markets }: Props) => {
  const router = useRouter();
  const theme = useTheme();
  const { sortBy, sortOrder } = useSortRouterParams();

  const buttonText = useBreakpointValue({
    base: 'See All',
    sm: 'See All Protected Investments',
  });

  const columnAlignments: ('left' | 'right')[] = ['left', 'left', 'right', 'right', 'right'];
  const columnWidths = [2, 2, 1.5, 1.5, 1.5, 2];

  const columnLabels = [
    { label: 'Borrow Asset', sortField: SortFields.Name },
    { label: 'Protected Against', sortField: SortFields.ProtectedAgainst },
    { label: 'Gross APY', sortField: SortFields.BorrowRate },
    { label: 'Protection Cost', sortField: SortFields.BorrowRate },
    { label: 'Net APY', sortField: SortFields.BorrowRate },
    { label: '', sortField: null },
  ];

  const protectedMarkets = markets.filter((market) => market.triggerAddress != null);
  const popularMarkets = protectedMarkets.slice(0, NUM_MARKETS_TO_SHOW);

  const { defaultInactiveMarkets, hasMarkets } = sortMarkets(
    popularMarkets,
    null,
    ['borrowBalanceUnderlying', 'investBalanceUnderlying'],
    {
      field: sortBy,
      order: sortOrder,
    },
  );

  const navigateToProtectedInvest = (marketId?: string) => () => {
    trackEvent('View Investment Clicked', { marketId });

    if (marketId == null) {
      router.push(`/protected-invest`);
    } else {
      router.push(`/protected-invest?market=${marketId}&tab=${BorrowModalTabs.Borrow}`);
    }
  };

  return (
    <Flex width="100%" flexDir="column" alignItems="center" mt="6em" px={{ base: 4, md: 6, xl: 8 }} mb="8em">
      <Box>
        <SectionLink href="/protected-invest" text="Invest with protection" />

        <Heading borderRadius="xl" backgroundColor="white" py="1.5rem" mb="3rem" textAlign="center" fontSize="2xl">
          Popular Protected Investments
        </Heading>
      </Box>

      <Container py="0" maxW={theme.maxPageWidth} width="100%" boxShadow="lg" px={0} my="0">
        <Table
          mt={0}
          px={0}
          borderRadius="0"
          borderLeftWidth="0"
          borderRightWidth="0"
          flex={1}
          columnAlignments={columnAlignments}
          columnLabels={columnLabels}
          columnWidths={columnWidths}
        >
          {!hasMarkets && <NoMarketsFound />}

          {defaultInactiveMarkets.map((market, index) => (
            <ProtectedBorrowTableRow
              columnAlignments={columnAlignments}
              columnWidths={columnWidths}
              index={index}
              key={market.id}
              market={market}
              onOpen={navigateToProtectedInvest(market.id)}
            />
          ))}
        </Table>
      </Container>

      <Flex justifyContent="center" my="5em">
        <Flex flexDirection="column" alignItems="center">
          <Button minW="17em" appButtonType={ButtonType.Primary} onClick={navigateToProtectedInvest()}>
            {buttonText}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default ProtectedBorrowTable;
