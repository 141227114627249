import { Box, Flex, SimpleGrid, useTheme } from '@chakra-ui/react';

import GetStartedButton from '../buttons/GetStartedButton';
import { HOW_IT_WORKS_BULLETS } from '../modals/how_it_works_modal/HowItWorksModal';
import Heading from '../shared/Heading';
import HowCozyWorksColumn from './HowCozyWorksColumn';
import React from 'react';
import Text from '@/components/shared/Text';

const HowCozyWorks: React.FC = () => {
  const theme = useTheme();

  return (
    <Flex flexDir="column" alignItems="center" px={{ base: '1em', md: '1.5em' }}>
      <Flex
        width="100%"
        flexDir="column"
        alignItems="center"
        maxW="1208px"
        pb="5.5em"
        borderRadius="3xl"
        pt="6em"
        background="linear-gradient(158.05deg, rgba(127, 175, 248, 0.028) 10.1%, rgba(137, 127, 248, 0.04) 85.64%);"
      >
        <Box maxW={theme.maxPageWidth} width="100%" px={{ base: 4, xl: 8 }}>
          <Flex flexDir="column" alignItems="center">
            <Heading fontSize={{ base: 'xl', md: '3xl' }} fontWeight="800" textAlign="center" mb="1rem">
              How Cozy’s Protection Works
            </Heading>
            <Text color="gray.500" fontSize={{ base: 'md', md: 'xl' }} textAlign="center" maxWidth="30em">
              Borrow assets protected from DeFi risks. Invest those assets without risking your principal.
            </Text>
          </Flex>
        </Box>

        <SimpleGrid
          backgroundColor="gray.50"
          borderRadius="xl"
          boxShadow="lg"
          columnGap="1"
          columns={{ base: 1, md: 2, lg: 2, xl: 4 }}
          id="cont"
          mt="4em"
          rowGap="1"
          overflow="hidden"
        >
          {HOW_IT_WORKS_BULLETS.map(({ body, number, title }) => (
            <HowCozyWorksColumn body={body} key={number} number={number} title={title} />
          ))}
        </SimpleGrid>

        <Box mt="4em">
          <GetStartedButton location="how-protection-works" />
        </Box>
      </Flex>
    </Flex>
  );
};

export default HowCozyWorks;
