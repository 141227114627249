import Heading from '@/components/shared/Heading';
import { HeadingProps } from '@chakra-ui/react';
import React from 'react';

const HeroHeading: React.FC<HeadingProps> = ({ children, ...props }: HeadingProps) => {
  return (
    <Heading fontWeight={800} fontSize={{ base: '2xl', md: '3xl', lg: '4xl', xl: '5xl' }} {...props}>
      {children}
    </Heading>
  );
};
export default HeroHeading;
