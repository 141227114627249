import { Flex, Icon, useTheme } from '@chakra-ui/react';

import { CgArrowLongRight } from 'react-icons/cg';
import Link from '../shared/Link';
import React from 'react';

interface Props {
  href: string;
  isExternal?: boolean;
  text: string;
}

const SectionLink: React.FC<Props> = ({ href, isExternal, text }: Props) => {
  const theme = useTheme();

  return (
    <Link isExternal={isExternal} color={theme.utilityColors.brandColor} href={href}>
      <Flex fontWeight="600" alignItems="center" textAlign="center" justifyContent="center">
        {text} <Icon ml={2} as={CgArrowLongRight} color="gray.300" size={24} />
      </Flex>
    </Link>
  );
};
export default SectionLink;
