import { Box, Flex, useTheme } from '@chakra-ui/react';
import useFetchMarketData, { SubgraphData } from '@/hooks/useFetchMarketData';

import Auditors from '@/components/landing/Auditors';
import DevelopWithCozy from '@/components/developers/DevelopWithCozy';
import DevelopersButton from '@/components/buttons/DevelopersButton';
import GetStartedButton from '@/components/buttons/GetStartedButton';
import { GetStaticProps } from 'next';
import Head from 'next/head';
import HeroSection from '@/components/landing/HeroSection';
import HowCozyWorks from '@/components/landing/HowCozyWorks';
import Investors from '@/components/landing/Investors';
import ProtectedInvestmentTable from '@/components/landing/ProtectedInvestmentTable';
import React from 'react';
import { filterByInvestMarkets } from '@/helpers/investHelpers';
import { preloadSubgraphData } from '@/helpers/preloadDataHelpers';
import useFetchInvestMarkets from '@/hooks/useFetchInvestMarkets';

interface Props {
  initialSubgraphData: SubgraphData;
}

const Index: React.FC<Props> = ({ initialSubgraphData }: Props) => {
  const theme = useTheme();

  const { markets } = useFetchMarketData(initialSubgraphData);
  const { enhancedMarkets } = useFetchInvestMarkets(markets);
  const investMarkets = filterByInvestMarkets(enhancedMarkets);

  return (
    <>
      <Head>
        <title>Cozy</title>
      </Head>

      <Flex flexDir="column" alignItems="center">
        <Box
          maxW={theme.maxPageWidth}
          width="100%"
          pt={{ base: '5em', md: '5em', lg: '9em', xl: '9em' }}
          pb={{ base: '60px', md: '60px', lg: '60px', xl: '150px' }}
          px={{ base: 4, md: 6, xl: 8 }}
        >
          <HeroSection
            accentText="Keep your crypto cozy."
            mainText="Invest in DeFi with protection against hacks, exploits, and more."
            primaryButton={<GetStartedButton location="hero" />}
            secondaryButton={<DevelopersButton location="hero" />}
          />
        </Box>
      </Flex>

      <HowCozyWorks />

      <ProtectedInvestmentTable markets={investMarkets} />

      <DevelopWithCozy />

      <Auditors />

      <Investors />
    </>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  return preloadSubgraphData();
};

export default Index;
