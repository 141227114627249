import { Flex, Stack } from '@chakra-ui/react';

import { ButtonType } from '@/types';
import { COZY_DEVELOPER_DOCS_URL } from '@/constants';
import DevelopWithCozyCard from './DevelopWithCozyCard';
import DeveloperDocsButton from '../buttons/DeveloperDocsButton';
import Heading from '../shared/Heading';
import React from 'react';
import SectionLink from '../landing/SectionLink';

const CARDS = [
  {
    title: 'Create Protection Markets',
    text: 'Learn how to create new Protection Markets using Cozy Protocol.',
    imageUrl: '/images/developers/create-market-guide.png',
    linkUrl: `${COZY_DEVELOPER_DOCS_URL}/for-developers/guides/how-do-i-create-a-protection-market`,
  },
  {
    title: 'Automatically purchase protection',
    text: 'Learn how to programatically purchase protection from a Protection Market.',
    imageUrl: '/images/developers/automatic-purchase-guide.png',
    linkUrl: `${COZY_DEVELOPER_DOCS_URL}/for-developers/guides/how-do-i-buy-protection-programmatically`,
  },
  {
    title: 'Manage protection programatically',
    text: 'Learn how to programatically deposit and withdraw from Protection Markets.',
    imageUrl: '/images/developers/manage-protection-guide.png',
    linkUrl: `${COZY_DEVELOPER_DOCS_URL}/for-developers/guides/how-do-i-manage-protection-programmatically`,
  },
];

const DevelopWithCozy: React.FC = ({}) => {
  return (
    <Flex flexDir="column" alignItems="center" px={{ base: '1em', md: '1.5em' }}>
      <Flex
        flexDirection="column"
        alignItems="center"
        maxW="1208px"
        width="100%"
        background="linear-gradient(158.05deg, rgba(127, 175, 248, 0.055) 10.1%, rgba(137, 127, 248, 0.05) 85.64%);"
        py="7.5rem"
        px={{ base: '3em', md: '5.5rem' }}
        borderRadius="3xl"
      >
        <SectionLink href={COZY_DEVELOPER_DOCS_URL} isExternal text="Develop with Cozy" />

        <Heading mt={4} fontSize="3xl" fontWeight="800">
          Programmatically create, buy, or provide protection
        </Heading>

        <Stack direction={{ base: 'column', md: 'row' }} spacing={10} mt="5rem" mb="5.5rem">
          {CARDS.map((card, index) => (
            <DevelopWithCozyCard key={index} {...card} />
          ))}
        </Stack>

        <DeveloperDocsButton
          appButtonType={ButtonType.Primary}
          buttonText="Explore Developer Docs"
          location="develop-with-cozy"
        />
      </Flex>
    </Flex>
  );
};
export default DevelopWithCozy;
