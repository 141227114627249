import Button from '../shared/Button';
import { ButtonType } from '@/types';
import { COZY_DEVELOPER_DOCS_URL } from '@/constants';
import React from 'react';
import { trackEvent } from '@/utils/analytics';

interface Props {
  appButtonType?: ButtonType;
  buttonText?: string;
  location: string;
}

const DeveloperDocsButton: React.FC<Props> = ({
  appButtonType = ButtonType.Primary,
  buttonText = 'Developer Docs',
  location,
}: Props) => {
  const onClick = () => {
    trackEvent('Developer Docs Clicked', { location });
    window.open(COZY_DEVELOPER_DOCS_URL);
  };

  return (
    <Button onClick={onClick} appButtonType={appButtonType} minW={{ base: '15em', sm: '12em', md: '15em' }}>
      {buttonText}
    </Button>
  );
};

export default DeveloperDocsButton;
