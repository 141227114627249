import { Box, Flex, Link, SimpleGrid, useTheme } from '@chakra-ui/react';

import { CgArrowLongRight } from 'react-icons/cg';
import Container from '../shared/Container';
import Heading from '../shared/Heading';
import { Image } from '../shared/Image';
import React from 'react';
import Text from '@/components/shared/Text';

const auditorLogos = ['quantstamp', 'peckshield'];
const COZY_SECURITY_GITBOOK_PAGE = 'https://docs.cozy.finance/cozy/security';

const CONTAINER_WIDTH = '262px';
const CONTAINER_HEIGHT = '171px';

const Auditors: React.FC = () => {
  const theme = useTheme();

  return (
    <Flex
      width="100%"
      flexDir="column"
      alignItems="center"
      pb={{ base: '2.5em', md: '7.5em' }}
      pt={{ base: '2.5em', md: '10em' }}
    >
      <Flex maxW={theme.maxPageWidth} width="100%" px={{ base: 4, xl: 8 }} flexDir="column">
        <Heading fontSize={{ base: 'xl', md: '3xl' }} fontWeight="800" textAlign="center" mb="1rem">
          Security at Cozy’s Core
        </Heading>
        <Text textAlign="center" fontSize={{ base: 'md', md: 'xl' }} color="gray.500">
          The Cozy Protocol has been audited by industry leading security firms
        </Text>
      </Flex>

      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={{ base: '1em', md: '3.75em' }}
        mt={{ base: '3em', md: '3.75em' }}
      >
        {auditorLogos.map((auditor, index) => (
          <Container
            display="flex"
            width={CONTAINER_WIDTH}
            height={CONTAINER_HEIGHT}
            key={index}
            boxShadow="lg"
            border="0px"
            justifyContent="center"
            alignItems="center"
          >
            <Image htmlWidth="198" htmlHeight="86" src={`/images/auditors/${auditor}.png`} alt={auditor} />
          </Container>
        ))}

        <Link isExternal href={COZY_SECURITY_GITBOOK_PAGE}>
          <Container
            alignItems="center"
            border="0px"
            boxShadow="lg"
            display="flex"
            height={CONTAINER_HEIGHT}
            justifyContent="center"
            width={CONTAINER_WIDTH}
          >
            <Flex alignItems="center">
              <Heading textAlign="center" fontSize="lg">
                Protocol Security
              </Heading>
              <Box ml={2} mb={'3px'}>
                <CgArrowLongRight size={24} />
              </Box>
            </Flex>
          </Container>
        </Link>
      </SimpleGrid>
    </Flex>
  );
};
export default Auditors;
