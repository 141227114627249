import { Box, Flex, Icon, useTheme } from '@chakra-ui/react';

import { CgArrowLongRight } from 'react-icons/cg';
import { Image } from '@/components/shared/Image';
import Link from '@/components/shared/Link';
import React from 'react';
import Text from '@/components/shared/Text';
import { motion } from 'framer-motion';

interface Props {
  imageUrl: string;
  linkUrl: string;
  text: string;
  title: string;
}

const IMAGE_WIDTH = 271;
const IMAGE_HEIGHT = 150;

const DevelopWithCozyCard: React.FC<Props> = ({ imageUrl, linkUrl, text, title }: Props) => {
  const theme = useTheme();

  const onClick = () => {
    window.open(linkUrl);
  };

  return (
    <motion.div
      style={{ display: 'flex', flex: 1 }}
      transition={{ duration: 0.15 }}
      whileHover={{ scale: 1.05, boxShadow: '0px 4px 7px rgba(5, 15, 25, 0.02), 0px 8px 24px rgba(5, 15, 25, 0.01)' }}
    >
      <Box
        onClick={onClick}
        cursor="pointer"
        flex={1}
        p={6}
        backgroundColor="white"
        borderRadius="lg"
        maxWidth={IMAGE_WIDTH + 2 * 16}
      >
        <Image
          alt={`An abstract image representing ${title}`}
          src={imageUrl}
          htmlHeight={IMAGE_HEIGHT}
          htmlWidth={IMAGE_WIDTH}
        />
        <Text mt={6} fontSize="md" fontWeight="600" color={theme.utilityColors.accentColor}>
          {title}
        </Text>
        <Text fontSize="md" mt={2}>
          {text}
        </Text>

        <Link isExternal href={linkUrl} color="gray.300">
          <Flex alignItems="center" mt={6} fontSize="md">
            Learn <Icon ml={2} as={CgArrowLongRight} color="gray.200" boxSize="24px" />
          </Flex>
        </Link>
      </Box>
    </motion.div>
  );
};
export default DevelopWithCozyCard;
